import React from "react";
import "./Card.css";

const Card = () => {
  return (
    <div className="Header-card">
      <h1>Meaure What You Value.</h1>
      <h3>
        We Buid Communities Through Measure <br />- For People and Businesses.
      </h3>
      <p>
        We service communiteis with tools, applications, and insights to build
        sustainable health and wealth.
      </p>
    </div>
  );
};

export default Card;
