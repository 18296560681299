import React from "react";
import "./How.css";

import { Container, Row, Col } from "reactstrap";
const How = () => {
  return (
    <Container>
      <Row className="how-it-works-section">
        <Col lg={4}>
          <h1>How it works</h1>
          <p>
            A community cannot grow what is does not measure. We provide people
            and businesses reports, insights, and instructions on how to build
            community and the management of the measures, services, and assets.
          </p>
        </Col>
        <Col lg={8}>
          <Row className="how-steps">
            <Col lg={3} className="stepp-vertical-line">
              <div></div>
            </Col>
            <Col lg={3}>
              <article>
                <div className="step-no">1</div>
                <p>
                  Measure asset acquisitions and the governance of those assets.
                </p>
              </article>
            </Col>
            <Col lg={3}>
              <article>
                <div className="step-no">2</div>
                <p>
                  Measure the members of the communities Health and Wellness.{" "}
                </p>
              </article>
            </Col>
            <Col lg={3}>
              <article>
                <div className="step-no">3</div>
                <p>
                  Measure the members of the community labor and resources.{" "}
                </p>
              </article>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default How;
