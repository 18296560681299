import React from "react";
import "./How.css";
import Logo from "../../Assets/Images/impact-republic-logo.png";

import { Container, Row, Col } from "reactstrap";
const Services = () => {
  return (
    <Container>
      <Row className="services-section">
        <Col lg={4}>
          <img className="logo" src={Logo} alt="impact-republic-logo" />
        </Col>
        <Col lg={8}>
          <Row>
            <article>
              <h1>Services</h1>
              <h3>Financial Analysis for Empowering Prosperity</h3>
              <p>
                Impact Republic provides comprehensive Financial Industry
                Reports for various sectors. Our services are designed to
                contribute to the wealth and economic prosperity of communities.
                These reports are designed to help businesses and institutions
                navigate the complex financial landscape and make strategic
                choices that lead to growth and success. Partner with Impact
                Republic today and unlock the power of our financial analysis
                and research services. Together, we can build a prosperous
                future for your community
              </p>
              <a
                className="contact-us-cta"
                href="mailto:hello@humanelevation.com"
              >
                Contact us now
              </a>
            </article>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
