import React, { useState } from "react";
import "./Navbar.css";
import Logo from "../../Assets/Images/impact-republic-logo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

function MainNav(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container>
      <Navbar {...args}>
        <NavbarBrand href="/">
          <img className="logo" src={Logo} alt="impact-republic-logo" />
        </NavbarBrand>
        <Nav>
          <NavItem>
            <NavLink active href="#">
              HOME
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active href="#how-it-works">
              HOW IT WORKS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active href="#services">
              SERVICES
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active href="#download">
              REPORTS
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink active href="#opportunity-zones">
              OPPORTUNITY ZONES
            </NavLink>
          </NavItem> */}

          <NavItem>
            <NavLink active href="#opportunity-zones">
              CONTACT US
            </NavLink>
          </NavItem>
        </Nav>
        {/* <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="/components/">Components</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">
                GitHub
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Reset</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <NavbarText>Simple Text</NavbarText>
        </Collapse> */}
      </Navbar>
    </Container>
  );
}

export default MainNav;
