import logo from "./logo.svg";
import church from "./Assets/Images/church-thumb.png";
import community from "./Assets/Images/community-thumb.png";
import dao from "./Assets/Images/dao-thumb.png";
import health1 from "./Assets/Images/health-thumb-1.png";
import health2 from "./Assets/Images/health-thumb-2.png";
import health3 from "./Assets/Images/health-thumb-3.png";
import opportunityZones from "./Assets/Images/opportunity-zones.png";

import "./App.css";
import { Container, Row, Col } from "reactstrap";
import MainNav from "./Components/Navbar";
import Card from "./Components/Card";
import How from "./Components/How";
import Reports from "./Components/Reports";
import Services from "./Components/Services";

function App() {
  return (
    <>
      <Row>
        <div className="mainBanner">
          <MainNav />
          <Container>
            <Card />
          </Container>
        </div>
      </Row>
      <div id="how-it-works">
        <How />
      </div>
      <div id="services">
        <Services />
      </div>
      <div id="reports">
        <Reports />
      </div>
      <Container>
        <div id="download">
          <Row className="download-section">
            <Col className="thumb" lg={4}>
              <img src={church} />
              <h2>
                Mega Churches <br /> in USA
              </h2>
              <p>
                In collaboration with Human Elevation we took a measure of
                Churches in North America. Churches are non-profit organization
                with missions to serves its patrons/congregation/and community
                at large. Churches can serve as an incredible resource and
                strategic partner to a community DAO.
              </p>
              <a href="https://www.dropbox.com/s/sdpp14ski7pxhqh/Megachurch%20Reserach%20Report.pdf?dl=1">
                Download Sample
              </a>
            </Col>
            <Col className="thumb" lg={4}>
              <img src={community} />
              <h2>
                Community <br />& Economics
              </h2>
              <p>
                In order to grow a community, there has to be a foundation to
                start. The foundation of the community is built from the people
                to make the effort to educate themselves on the matters of
                others and provide time, action, and solutions that lead to
                positive resolve and elevation.
              </p>
              <a href="https://www.dropbox.com/s/0h7oqxp60ikmwjr/IR_Community_Economic_Report_Cooperatives.pdf?dl=1">
                Download Sample
              </a>
            </Col>
            <Col className="thumb" lg={4}>
              <img src={dao} />
              <h2>
                Building a <br />
                community <br />
                with a DAO
              </h2>
              <p>
                Learn how a DAO can create governance for communities. We
                provide instruction and insights on how to build a community
                governance around on the mission they seek to to improve the
                health and wealth of the community.
              </p>
              <a href="https://www.dropbox.com/s/taziqpm0f0hzg8i/IR_Building_Community_Witth_DAO_Report%20.pdf?dl=1">
                Download Sample
              </a>
            </Col>
            <Col className="thumb" lg={4}>
              <img src={health1} />
              <h2>
                HEALTH &<br />
                COMMUNITY
                <br />
                PART 01
              </h2>
              <p>
                How to take measures on the health of a community. Measurement
                tools for community health improvements is the exploration and
                insight of this report. The knowledge and role for community
                health profiles and their short and long-term benefit is
                empowering.
              </p>
              <a href="https://www.dropbox.com/s/rfhqgk1xqlr0frc/Community%20and%20Health%20Part%2001.pdf?dl=1">
                Download Sample
              </a>
            </Col>
            <Col className="thumb" lg={4}>
              <img src={health2} />
              <h2>
                HEALTH &<br />
                COMMUNITY
                <br />
                PART 02
              </h2>
              <p>
                Improving health in the community: A role for performance
                monitoring in the community. Have you considered the measurement
                tools to understand the health of a community? Learn where
                performance indicators can fulfill a need in our community.
              </p>
              <a href="https://www.dropbox.com/s/pp1hvj24epo2y22/Community%20and%20Health%20Part%2002.pdf?dl=2">
                Download Sample
              </a>
            </Col>
            <Col className="thumb" lg={4}>
              <img src={health3} />
              <h2>
                HEALTH &<br />
                COMMUNITY
                <br />
                PART 03
              </h2>
              <p>
                Improving Health in the Community: Performance Indicator Sets.
                This report provides the key indicators to use in the
                measurement and evaluation of health in a community. Performance
                monitoring has a big impact on the improvements and strides a
                community makes, learn how.
              </p>
              <a href="https://www.dropbox.com/s/dke43oa2uqalkry/Community%20and%20Health%20Part%2003.pdf?dl=3">
                Download Sample
              </a>
            </Col>
          </Row>
        </div>
      </Container>

      <Row>
        <div className="page-footer">
          <Container>
            {/* <div id="opportunity-zones" className="footer-title">
              <h1>Opportunity Zones</h1>
              <h4>
                Most communities in America are currently stagnant or declining
                over the past 5 years. Learn how to acquire assets in your
                community under a tax and fund structure designed to support the
                gRowth of real estate assets.
              </h4>
              <a href="https://www.dropbox.com/s/fzifjfuwmnx531h/Impact_Republic_QOZ_Real_Estate_Report.pdf?dl=1">
                Download Sample
              </a>
            </div> */}
            {/* <img src={opportunityZones} /> */}
            <p>
              <strong>Impact Republic.</strong> 386 Park Avenue South, 18th
              Floor NY, NY 10016 P.
              <br />
              212-441-0000 | hello@humanelevation.com
              <br />© Copyright {new Date().getFullYear()} - Human Elevation
            </p>
          </Container>
        </div>
      </Row>
    </>
  );
}

export default App;
