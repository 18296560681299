import React from "react";
import { Row } from "reactstrap";
import "./Report.css";

const Reports = () => {
  return (
    <Row className="reports-section">
      <h1>Our Reports</h1>
      <p>
        Impact Republic provides reports to serve as blueprints for community
        members to learn, discuss and apply where applicable. Overtime we seek
        to create knowledge that will create measures around the health and
        wealth of our communities. Knowledge is the first step to being able to
        take proper action.
      </p>
    </Row>
  );
};

export default Reports;
